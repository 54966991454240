<template>
  <v-list-item @click="selectDeliveryMethod">
    <v-list-item-avatar>
      <v-icon>mdi-package-variant-closed</v-icon>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title>
        {{ deliveryMethod.title }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ deliveryMethod.description }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
	name: "DeliveryMethodsListItem",
	props: {
		deliveryMethod: {
      type: Object,
      default: () => {}
    }
	},
	methods: {
		selectDeliveryMethod() {
			this.$emit('selectDeliveryMethod', this.deliveryMethod)
		}
	}
}
</script>

<style scoped>

</style>